import { Routes, Route } from "react-router-dom"

import routes from "./routes/routes"
import styled from "styled-components"

function Content() {
  return (
    <ContentWrapper>
      <Routes>
        {routes.map((route, idx) => {
          return route.element && <Route key={idx} path={route.path} element={<route.element />} />
        })}
      </Routes>
    </ContentWrapper>
  )
}

export default Content

const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`
