import { useRef } from "react"

export default function useClickOverSwipe(timeThreshold = 150, dragThreshold = 20){
  const clickDown = useRef(0)
  const clickMove = useRef({
    x: 0,
  })
  
  function handleDown(e) {
    clickDown.current = e.timeStamp
    clickMove.current = {
      x: e.clientX,
    }
  }

  function handleUp(e, clickCallback, dragCallBack) {
    const diff = e.timeStamp - clickDown.current
    const xMove = Math.abs(e.clientX - clickMove.current.x)
    if (diff < timeThreshold && xMove < dragThreshold) {
      clickCallback && clickCallback(e)
    } else{
      const coords= {
        xDown: clickMove.current.x,
        xUp: e.clientX,
        distanceX: e.clientX - clickMove.current.x,
        directionX: e.clientX - clickMove.current.x < 0 ? "right": "left",
      }
      dragCallBack && dragCallBack(e, coords)
    }
  }

  return {
    handleDown,
    handleUp
  }
}