import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { fetchLiveData } from "./fetchLiveData"
import useStore from "@state/store"

export function useFilteredLiveData() {
  const [data, setData] = useState(null)
  const [filteredData, setFilteredData] = useState(null)
  const location = useLocation()
  const { search } = useLocation() // e.g. ?MTJCo2NyaXM=

  const getUnitIDsFromQuery = () => {
    const decoded = atob(search.substring(1))
    const unitPart = decoded.split("£")[0]
    return unitPart.split(",")
  }

  const fetchData = async () => {
    try {
      const formattedData = await fetchLiveData()
      if (formattedData) {
        setData(formattedData)
        const unitIDs = getUnitIDsFromQuery()
        const filtered = formattedData.filter((item) => unitIDs.includes(item.unitID))
        setFilteredData(filtered)
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [location.search]) // Fetch data when the location search params change

  return { data, filteredData, fetchData }
}
