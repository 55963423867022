import Favourites from "@pages/Favourites"
// import ErrorMessage from "@pages/ErrorMessage"

const routes = [
  {
    name: "HOME",
    path: "/",
    onNav: true,
    element: Favourites,
  },
  // { path: "/*", name: "Not Found", element: ErrorMessage },
]

export default routes
