import { create } from "zustand"
import { combine } from "zustand/middleware"
import { initialState } from "./initialState"
import { InitialState } from "./types.store"

export interface SocketPayload {
   data: any
}

type SetLocalState = {
   setLocalState: (state: Partial<InitialState>) => void
}

const mutations = (set: any) => {
   // only reset based on specific key
   const resetState = (key: string) => {
      set((state: any) => {
         const newState = { ...state }
         newState[key] = initialState[key]
         return newState
      })
   }
   return {
      path: "/",
      setLocalState: (stateToUpdate: Partial<InitialState>) => {
         set({ ...stateToUpdate })
      },
      resetState
   }
}

const useStore = create<
   InitialState & SetLocalState & { resetState: (v: string) => void }
>()(combine(initialState, mutations))
export default useStore
