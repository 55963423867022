import React, { Fragment } from "react"
import { useEffect, useState } from "react"
import styled from "styled-components"

import Popup from "@components/Popup"

import useStore from "@state/store"
import useClickOverSwipe from "@utils/useClickOverSwipe"

const Gallery = React.memo(function Gallery() {
   const [images, setImages] = useState<string[]>([])
   // const [popupOpen, setPopupOpen] = useState(false)
   // const [selectedGalleryImage, setSelectedGalleryImage] = useState(0)
   const { popupGallery, popupGalleryIndex, setLocalState } = useStore(s => ({
      popupGallery: s.popupGallery,
      popupGalleryIndex: s.popupGalleryIndex,
      setLocalState: s.setLocalState
   }))
   const { handleDown, handleUp } = useClickOverSwipe()
   const isMobile = innerWidth < 1000

   useEffect(() => {
      const loadImages = async () => {
         const imageModules = isMobile
            ? import.meta.glob(
                 "../../../public/assets/images/residences/gallery/thumbnail/*.{jpg,png,webp}"
              )
            : import.meta.glob(
                 "../../../public/assets/images/residences/gallery/*.{jpg,png,webp}"
              )
         const imagePaths: string[] = await Promise.all(
            Object.entries(imageModules).map(async ([src, importer]) => {
               const module: any = await importer()
               return module.default
            })
         )
         setImages(imagePaths)
      }
      loadImages()
      return () => {
         setImages([])
      }
   }, [])

   function handleIndexChange(dir) {
      const max = images.length - 1
      if (dir) {
         setLocalState({
            popupGalleryIndex:
               popupGalleryIndex === max ? 0 : popupGalleryIndex + 1
         })
      } else {
         setLocalState({
            popupGalleryIndex:
               popupGalleryIndex === 0 ? max : popupGalleryIndex - 1
         })
      }
   }

   function imageClick() {
      setLocalState({
         popupGallery: false
      })
   }

   function imageDrag(e, coords) {
      handleIndexChange(coords.directionX === "right")
   }

   return (
      <Fragment>
         {/* <Wrapper>
            <div className="image-grid">
               {images.map((src, index) => (
                  <div
                     key={index}
                     style={{ backgroundColor: "black", position: "relative" }}
                  >
                     <img
                        key={index}
                        src={src}
                        onClick={() => {
                           setSelectedGalleryImage(index)
                           setPopupOpen(true)
                        }}
                        alt={`Image ${index + 1}`}
                     />
                  </div>
               ))}
            </div>
         </Wrapper> */}
         <Popup
            openState={popupGallery}
            setOpenState={v => {
               setLocalState({
                  popupGallery: v
               })
            }}
            durationMS={500}
            closeColor="white"
            style={{
               backgroundColor: "rgb(32,32,32)"
            }}
            onClose={() => {
               setLocalState({
                  popupGalleryIndex: 0
               })
            }}
         >
            {images.map((src, index) => (
               <PopupImage
                  key={index}
                  src={src}
                  onPointerDown={handleDown}
                  onPointerUp={e => {
                     handleUp(e, imageClick, imageDrag)
                  }}
                  style={{
                     opacity: popupGalleryIndex === index ? 1 : 0,
                     pointerEvents: popupGalleryIndex === index ? "all" : "none"
                  }}
                  alt={`Image ${index + 1}`}
               />
            ))}
            <Controls>
               <img
                  src="/assets/images/left.svg"
                  onClick={() => handleIndexChange(false)}
               />
               <span>
                  {popupGalleryIndex + 1} / {images.length}
               </span>
               <img
                  src="/assets/images/right.svg"
                  onClick={() => handleIndexChange(true)}
               />
            </Controls>
         </Popup>
      </Fragment>
   )
})

export default Gallery

const Wrapper = styled.div`
   width: 100%;
   height: 100%;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);

   .image-grid {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      height: 50%;
      max-height: 50%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      gap: 10px;
      overflow-y: auto;
      padding: 1rem;
   }

   .image-grid img {
      width: 100%;
      height: 100%;
      aspect-ratio: 1/1;
      object-fit: cover;
      cursor: pointer;
   }

   .image-grid img:nth-child(1),
   .image-grid img:nth-child(3n + 4) {
      grid-column: 1 / -1;
      grid-row: 1 / -1;
   }
`

const PopupImage = styled.img`
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgb(32, 32, 32);
   object-fit: contain;
   object-position: center;
   transition: opacity 0.4s ease-out;
`

const Controls = styled.div`
   position: absolute;
   z-index: 1;
   width: 100%;
   height: 50px;
   bottom: 20px;
   color: white;
   font-size: 2rem;
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 50px;
   pointer-events: all;

   & img {
      height: 60%;
      cursor: pointer;
   }
`
