type Unit = {
  unitID: string
  unitType: string
  aspect: string
  beds: number
  floor: number
  baths: number
  sqft: number
  price: number
  status: string
  exsqft?: number
  view?: string
}

export interface RawPropertyData {
  UNITID: string
  UNITTYPE: string
  SQFT: number
  EXSQFT: number
  BEDS: number
  BATHS: number
  PRICE: number
  LEVEL: number
  ASPECT: string
  STATUS: string
  VIEW: string
}

const fetchPage = async (offset = "") => {
  const params = new URLSearchParams({
    pageSize: "100",
    maxRecords: "150",
    ...(offset && { offset }),
  })

  const response = await fetch(`${import.meta.env.VITE_DATA_URL}?${params}`, {
    headers: {
      Authorization: `Bearer ${import.meta.env.VITE_DATA_API_KEY}`,
    },
  })

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`)
  }

  return response.json()
}

export const fetchLiveData = async (): Promise<Unit[]> => {
  try {
    let allRecords: RawPropertyData[] = []
    let offset = ""
    let hasMore = true

    while (hasMore) {
      const { records, offset: newOffset } = await fetchPage(offset)
      allRecords = allRecords.concat(records.map((record: { fields: RawPropertyData }) => record.fields))
      offset = newOffset
      hasMore = !!newOffset
    }

    const formattedData: Unit[] = allRecords.map((row: RawPropertyData) => ({
      unitID: row.UNITID || "",
      unitType: row.UNITTYPE || "",
      status: row.STATUS || "Available",
      sqft: row.SQFT || 0,
      exsqft: row.EXSQFT || 0,
      beds: row.BEDS || 0,
      baths: row.BATHS || 0,
      price: row.PRICE || 0,
      aspect: row.ASPECT || "0",
      floor: row.LEVEL || 0,
      view: row.VIEW,
    }))

    return formattedData
  } catch (error) {
    console.error("Error fetching data:", error)
    throw new Error(error.message)
  }
}
