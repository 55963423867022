import { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import gsap from "gsap"

import Floorplan from "@components/favorites/Floorplan"
// import View from "@components/favorites/View"
// import Gallery from "@components/favorites/Gallery"
import InfoCard from "@components/favorites/InfoCard"

import { TUnitDetailsSection } from "@state/types.store"
import useStore from "@state/store"

function UnitCard({ unit, ...props }) {
   const [unitDetailsSection, setUnitDetailsSection] =
      useState<TUnitDetailsSection>("floorplan")
   const setLocalState = useStore(s => s.setLocalState)

   const floorplanContainerRef = useRef(null)
   const viewContainerRef = useRef(null)
   const gallerContainerRef = useRef(null)

   // useEffect(() => {
   //    gsap.set([viewContainerRef.current, gallerContainerRef.current], {
   //       autoAlpha: 0
   //    })
   // }, [])

   // useEffect(() => {
   //    const tl = gsap.timeline()
   //    if (unitDetailsSection === "floorplan") {
   //       tl.to([viewContainerRef.current, gallerContainerRef.current], {
   //          autoAlpha: 0,
   //          duration: 0.3
   //       }).to(
   //          floorplanContainerRef.current,
   //          { autoAlpha: 1, duration: 0.6 },
   //          "<"
   //       )
   //    } else if (unitDetailsSection === "view") {
   //       tl.to([floorplanContainerRef.current, gallerContainerRef.current], {
   //          autoAlpha: 0,
   //          duration: 0.3
   //       }).to(viewContainerRef.current, { autoAlpha: 1, duration: 0.6 }, "<")
   //    } else if (unitDetailsSection === "gallery") {
   //       tl.to([floorplanContainerRef.current, viewContainerRef.current], {
   //          autoAlpha: 0,
   //          duration: 0.3
   //       }).to(gallerContainerRef.current, { autoAlpha: 1, duration: 0.6 }, "<")
   //    }
   // }, [unitDetailsSection])

   return (
      <Wrapper
         activeSection={unitDetailsSection}
         id="residenceDetails"
         {...props}
      >
         <div className="left">
            
            <InfoCard selectedUnit={unit} />
            <div className="disclaimer">PURCHASER ACKNOWLEDGES AND ACCEPTS THAT THIS DRAWING (A) REFLECTS A PRELIMINARY SKETCH FOR
ILLUSTRATIVE PURPOSES ONLY, (B) IS BEING PROVIDED AT PURCHASER’S REQUEST, AND (C) HAS NEITHER BEEN
REVIEWED NOR APPROVED BY APPLICABLE GOVERNMENTAL AUTHORITIES (INCLUDING WITHOUT LIMITATION DOB
AND AG, TO THE EXTENT APPLICABLE), AND SPONSOR MAKES NO REPRESENTATIONS REGARDING WHETHER SUCH
GOVERNMENTAL AUTHORITIES SHALL APPROVE THIS DRAWING IN ITS CURRENT FORM. ALL MEASUREMENTS AND
SQUARE FOOTAGES ARE APPROXIMATE AND EXCEED USABLE FLOOR AREA. THIS IS NOT AN OFFER. NEITHER THIS
DRAWING NOR ANY OTHER INFORMATION SET FORTH HEREIN SHALL BE CONSTRUED TO OBLIGATE OR BIND EITHER
PARTIES TO ANY SALES, TERMS OF SALE, AND/OR ANY OTHER OBLIGATIONS. SPONSOR MAKES NO
REPRESENTATIONS OR GUARANTEES EXCEPT AS MAY BE SET FORTH IN THE OFFERING PLAN THE COMPLETE
OFFERING TERMS ARE IN AN OFFERING PLAN AVAILABLE FROM SPONSOR. FILE NO. CD220253. SPONSOR: 720 WEA
VENTURES LLC, C/O INTERVEST CAPITAL PARTNERS, 425 PARK AVENUE, 35TH FLOOR, NEW YORK, NY 10022. EQUAL
HOUSING OPPORTUNITY.
</div>
            <ul className="menu-items">
               {/* <li
                  // style={{
                  //    opacity:
                  //       unitDetailsSection === "floorplan" ? 1 : 0.3
                  // }}
                  onClick={() => setUnitDetailsSection("floorplan")}
               >
                  Floorplan
               </li> */}
               <li
                  // style={{
                  //    opacity: unitDetailsSection === "view" ? 1 : 0.3
                  // }}
                  onClick={() => {
                     //setUnitDetailsSection("view")
                     setLocalState({
                        activeView: unit?.view,
                        selectedUnit: unit,
                        popupView: true
                     })
                  }}
               >
                  View
               </li>
               <li
                  // style={{
                  //    opacity: unitDetailsSection === "gallery" ? 1 : 0.3
                  // }}
                  onClick={() => {
                     // setUnitDetailsSection("gallery")
                     setLocalState({
                        popupGallery: true
                     })
                  }}
               >
                  Gallery{" "}
               </li>
            </ul>
         </div>
         <div className="right-panel">
            <div
               // style={{ opacity: 0, width: "100%" }}
               style={{ width: "100%" }}
               ref={floorplanContainerRef}
            >
               <Floorplan unit={unit} />
            </div>
            {/* <div ref={viewContainerRef}>
               <View views={unit?.view} />
            </div> */}
            {/* <div ref={gallerContainerRef} id="gallery">
               <Gallery />
            </div> */}
         </div>
      </Wrapper>
   )
}

export default UnitCard

const Wrapper = styled.div<{
   activeSection: TUnitDetailsSection
}>`
   position: relative;
   border-radius: 2rem;
   width: 100%;
   height: 100%;
   font-family: "Masque-Hairline";
   line-height: 1;
   text-transform: uppercase;
   background-color: #fff;
   margin-top: 2em;
   transition: opacity 0.3s ease-in-out;
   overflow: clip;

   .disclaimer {
      width: 80%;
      font-size: 1rem;
      line-height: 20px;
      position: absolute;
      font-family: stolzl;
      left: 3em;
      top: 50%;
      text-transform: none;
      color: #a1a1a1;
      text-align: justify;
      transform: translateY(-50%);

      @media (max-width: 600px) and (orientation: portrait) {
      width: 80vw;
      font-size: 0.75rem;
      line-height: 10px;
      font-family: stolzl;
      left: 4em;
      top: 30%;
      color: #a1a1a1;
      text-align: justify;
            }

      @media (max-width: 900px) and (orientation: landscape) {
      width: 40vw;
      font-size: 0.7rem;
      line-height: 7px;
      font-family: stolzl;
      left: 4em;
      top: 55%;
      color: #a1a1a1;
      text-align: justify;
            }
    }

   .left {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 37%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 3em;
      z-index: 2;
      pointer-events: none;
   }

   .menu-items {
      color: #000;
      list-style: none;
      font-family: "Masque-Hairline";
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1em;
      margin: 0;
      padding: 0;

      li {
         font-size: 2.5em;
         letter-spacing: 0.1em;
         cursor: pointer;
         transition: opacity 400ms ease-out;
         pointer-events: all;
      }

      li:active {
         opacity: 0.5;
      }
   }

   .right-panel {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: flex-end;

      & > * {
         touch-action: pan-y;
      }
   }
`
