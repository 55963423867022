import styled from "styled-components"
import React, {
   Fragment,
   useCallback,
   useEffect,
   useRef,
   useState
} from "react"

import Popup from "@components/Popup"

import useStore from "@state/store"
import InfoCard from "./InfoCard"

// function throttle(func: any, limit: any) {
//    let lastFunc: any
//    let lastRan: any
//    return function (...args: any) {
//       if (!lastRan) {
//          func.apply(this, args)
//          lastRan = Date.now()
//       } else {
//          clearTimeout(lastFunc)
//          lastFunc = setTimeout(() => {
//             if (Date.now() - lastRan >= limit) {
//                func.apply(this, args)
//                lastRan = Date.now()
//             }
//          }, limit - (Date.now() - lastRan))
//       }
//    }
// }

const partIndex = {
   North: ["7", "9"],
   East: ["8", "10"],
   South: ["3", "2", "1"],
   West: ["6", "5", "4"]
}

function findMatchingKeys(item) {
   return Object.keys(partIndex).filter(key => partIndex[key].includes(item))
}
const View: React.FC = React.memo(function View() {
   const [parts, setParts] = useState<string[]>([])
   const [suffix, setSuffix] = useState<string>("")
   const [activeIndex, setActiveIndex] = useState(0)
   const [path, setPath] = useState<string | null>(null)
   const { activeView, popupView, selectedUnit, setLocalState } = useStore(
      s => ({
         activeView: s.activeView,
         popupView: s.popupView,
         selectedUnit: s.selectedUnit,
         setLocalState: s.setLocalState
      })
   )

   // const imgRef = useRef<HTMLImageElement>(null)

   useEffect(() => {
      if (activeView) {
         const splitParts = activeView.split("-")
         const lastPart = splitParts.pop()
         setSuffix(lastPart?.toUpperCase() || "")
         setParts(splitParts)
      }
   }, [activeView])

   useEffect(() => {
      if (parts.length) {
         const view = `${parts[activeIndex]}-${suffix}.webp`
         setPath(view)
         //   syncState({ activeView: view })
      }
   }, [parts, activeIndex, suffix])

   const handleSetActiveView = useCallback((index: number) => {
      setActiveIndex(index)
   }, [])

   return (
      <Fragment>
         {/* <Wrapper>
            <img
               style={{
                  width: "100%",
                  height: "100%",
                  cursor: "pointer"
               }}
               ref={imgRef}
               src={`/assets/images/residences/views/${activeView}`}
               alt="View"
               onClick={() => setPopupOpen(true)}
            />
            {parts.length > 1 && (
               <div className="views-buttons">
                  {parts.map((part, index) => (
                     <button
                        key={index}
                        onClick={() => handleSetActiveView(index)}
                     >
                        {findMatchingKeys(part)}
                     </button>
                  ))}
               </div>
            )}
         </Wrapper> */}
         <Popup
            openState={popupView}
            setOpenState={() => {
               setLocalState({
                  popupView: false
               })
            }}
            durationMS={500}
            closeColor="white"
            style={{
               backgroundColor: "rgb(32,32,32)"
            }}
            onClose={() => {
               setLocalState({
                  activeView: null,
                  selectedUnit: null
               })
            }}
         >
            {path && (
               <PopupImage
                  src={`/assets/images/residences/views/${path}`}
                  onClick={() => {
                     setLocalState({
                        popupView: false
                     })
                  }}
               />
            )}
            {parts.length > 1 && (
               <ViewButtons className="views-buttons">
                  {parts.map((part, index) => (
                     <button
                        key={index}
                        onClick={() => handleSetActiveView(index)}
                     >
                        {findMatchingKeys(part)}
                     </button>
                  ))}
               </ViewButtons>
            )}
            <InfoWrapper>
               <InfoCard selectedUnit={selectedUnit} className="info" />
               <h2>{findMatchingKeys(parts[activeIndex])} View</h2>
            </InfoWrapper>
         </Popup>
      </Fragment>
   )
})

export default View

const PopupImage = styled.img`
   position: absolute;
   top: 50%;
   left: 50%;
   width: 100%;
   height: auto;
   transform: translate(-50%, -50%);
   transform-origin: center;
   background-color: rgb(32, 32, 32);
   object-fit: contain;
   object-position: center;
   transition: opacity 0.4s ease-out;
   cursor: pointer;

   @media (orientation: portrait) {
      width: 100vh;
      height: 100vw;
      transform: translate(-50%, -50%) rotate(90deg);
   }
`

const ViewButtons = styled.div`
   position: absolute;
   bottom: 3em;
   right: 3em;
   display: flex;
   flex-direction: row;
   gap: 1em;
   padding: 1.5em;
   border-radius: 1.5em;

   button {
      padding: 1em 1.5em;
      background: transparent;
      background-color: white;
      border: 1px solid #000;
      color: black;
      cursor: pointer;
      text-transform: uppercase;
      font-family: "Masque-Hairline";

      &:hover {
         background: #000;
         color: #fff;
      }
   }

   &.hide {
      display: none;
   }

   @media (orientation: portrait) {
      transform: translate(-25%, -100%) rotate(90deg);
      bottom: 0;
      left: 0;
      height: fit-content;
      width: fit-content;
   }
`

const InfoWrapper = styled.div`
   position: absolute;
   bottom: 2em;
   left: 2em;

   .info {
      position: relative;
      background-color: unset;

      > * {
         color: white;
      }

      & .details {
         display: none;
      }
   }

   & h2 {
      font-family: "Masque-Hairline";
      font-size: 3em;
      line-height: 1;
      color: white;
   }

   @media (orientation: portrait) {
      transform: translate(-10%, 50%) rotate(90deg);
      top: 0;
      left: 0;
      height: fit-content;
      width: fit-content;
   }
`
