import React from "react"
import styled from "styled-components"
import * as Separator from "@radix-ui/react-separator"
import useStore from "@state/store"

const formatPrice = (val: number) => {
  const formattedVal = Number(val).toLocaleString()
  return formattedVal
}

type InfoCardProps = {
  selectedUnit: any
  [x: string]: unknown
}

const InfoCard: React.FC<InfoCardProps> = ({ selectedUnit, ...props }) => {
  const { unitDetailsSection } = useStore((state) => ({
    unitDetailsSection: state.unitDetailsSection,
  }))
  return (
    <InfoCardWrapper unitDetailsSection={unitDetailsSection} {...props}>
      <div className="info-card-content">
        <div className="unit-info">
          <div className="unit-title">{selectedUnit.unitType.includes("TH") ? "Townhouse": "Residence"}</div>
          <div style={{ display: "flex" }}>
            <div className="unit-number">{selectedUnit?.unitType}</div>
          </div>
          <div className="details">
            <div className="bed">{selectedUnit?.beds} Bed</div>
            <Separator.Root className="separator-root" decorative orientation="vertical" />
            <div className="bath">{selectedUnit?.baths} Bath</div>
            <Separator.Root className="separator-root" decorative orientation="vertical" />
            <div className="sqft">{selectedUnit?.sqft} sq ft</div>
            <Separator.Root className="separator-root" decorative orientation="vertical" />
            <div className="price">${formatPrice(selectedUnit?.price)}</div>
          </div>
        </div>
      </div>
    </InfoCardWrapper>
  )
}

export default InfoCard

const InfoCardWrapper = styled.div<{
  unitDetailsSection: string
}>`
  font-family: Stolzl;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.1);
  width: auto;
  height: auto;
  border-radius: 1rem;
  opacity: 1;

   & > * {
      color: #000;
   }

  .info-card-content {
    border-radius: 1rem;
    font-family: "Masque-Hairline";
    display: flex;

    .unit-title {
      font-size: 3em;
      line-height: 1;
    }

    .unit-number {
      font-size: 9em;
      text-align: left;
      line-height: 0.75;
    }

    .floor {
      font-family: "Stolzl";
      font-size: 2em;
      letter-spacing: 0.2em;
      font-weight: 100;
      margin: 60px 0 0;
    }

    .details {
      height: 2em;
      margin-top: 1em;
      font-family: "Stolzl";
      display: flex;
      align-items: center;

      .bed,
      .bath,
      .sqft,
      .price {
        font-size: 1.2em;
        letter-spacing: 0.1em;
        width: fit-content;
        white-space: nowrap;
      }
    }

    .separator-root {
      background-color: #000;
      transition: background-color 0.3s ease-in-out;
    }

    .separator-root[data-orientation="vertical"] {
      height: 100%;
      width: 1px;
      margin: 0 10px;
    }
  }
`
