import React, { useEffect, Suspense } from "react"

import UnitCard from "@components/UnitCard/UnitCard"
import Gallery from "@components/favorites/Gallery"
import View from "@components/favorites/View"
import gsap from "gsap"
import { useFilteredLiveData } from "@data/useFilteredLiveData"
import { useLocation } from "react-router-dom"

const Favorites: React.FC = ({}) => {
  const { fetchData, filteredData } = useFilteredLiveData()
  const location = useLocation()
  const { search } = useLocation()
  const name = atob(search.substring(1)).split("£")[1]
  const date = new Date()
  const time = date.getHours()
  let message

  if (!name) message = ""
  else if (time < 12) message = "Good Morning"
  else if (time >= 12 && time < 17) message = "Good Afternoon"
  else if (time >= 17) message = "Good Evening"

  gsap.fromTo(".image", { x: -20, opacity: 0 }, { x: 0, opacity: 1, duration: 1, ease: "power2.inOut" })
  gsap.fromTo(".message", { x: -20, opacity: 0 }, { x: 0, opacity: 1, duration: 1, ease: "power2.inOut" })
  gsap.fromTo(".buttons", { x: -20, opacity: 0 }, { x: 0, opacity: 1, duration: 1, ease: "power2.inOut" })

  useEffect(() => {
    fetchData()
  }, [location.search])

  function handleClick() {
    gsap.to(".splash", { autoAlpha: 0, duration: 1, ease: "power2.inOut" })
  }

  function handleDone() {
    gsap.to(".splash", { autoAlpha: 1, duration: 1, ease: "power2.inOut" })
  }

  return (
    <Suspense
      fallback={<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>Loading...</div>}>
      <div
        className="splash"
        style={{
          position: "absolute",
          height: "100vh",
          width: "100vw",
          zIndex: 10,
          backgroundColor: "#000",
        }}>
        <img
          className="image"
          style={{ opacity: 0, width: "100%", objectFit: "contain", height: "100%" }}
          src="assets/images/shared.jpg"
        />
        <div
          className="buttons"
          style={{
            cursor: "pointer",
            opacity: 0,
            display: "flex",
            position: "absolute",
            flexDirection: "column",
            gap: "2rem",
            bottom: "15%",
            left: "5%",
          }}>
          <div onClick={handleClick} style={{ display: "flex", borderBottom: "1px solid white", width: "90%" }}>
            <div
              style={{
                pointerEvents: "all",
                zIndex: 11,
                padding: "1.25rem",
                fontSize: "15px",
                fontFamily: "stolzl",
                color: "white",
                whiteSpace: "nowrap",
              }}>
              View Favorites
            </div>
            <img
              width="10%"
              style={{ marginLeft: "auto", padding: "0.25rem", marginRight: "10px" }}
              src="/assets/images/right.svg"
              alt=""
            />
          </div>
          <div style={{ display: "flex", borderBottom: "1px solid white", width: "90%" }}>
            <a
              href="/brochure.pdf"
              download="brochure.pdf"
              style={{
                zIndex: 11,
                textDecoration: "none",
                color: "#fff",
                padding: "1.25rem",
                fontSize: "15px",
                whiteSpace: "nowrap",
                fontFamily: "stolzl",
              }}>
              Download Brochure
            </a>
            <img
              width="10%"
              style={{ transform: "rotate(90deg)", padding: "0.25rem", marginLeft: "auto", marginRight: "10px" }}
              src="/assets/images/right.svg"
              alt=""
            />
          </div>
        </div>
        <div
          className="message"
          style={{
            opacity: 0,
            display: "flex",
            position: "absolute",
            flexDirection: "column",
            gap: "2rem",
            top: "10%",
            left: "5%",
          }}>
          <div
            style={{
              zIndex: 11,
              padding: "1.25rem",
              fontSize: "3rem",
              fontFamily: "stolzl",
              color: "white",
              whiteSpace: "nowrap",
            }}>
            {message} <br /> <span style={{ fontSize: "5rem" }}>{name}</span>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          padding: "2em 2em",
        }}>
        {filteredData && filteredData?.map((unit) => <UnitCard key={unit.unitID} unit={unit} />)}
      </div>
      <div
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          margin: "3rem",
          fontFamily: "stolzl",
          padding: "1rem 1.25rem",
          fontSize: "2rem",
          backgroundColor: "white",
          border: "1px solid #000",
          zIndex: 8,
          display: "flex",
          gap: "0.5rem",
          justifyContent: "center",
          alignItems: "center",
          width: "150px",
        }}
        onClick={handleDone}>
        <img width="20%" src="assets/images/left.svg" style={{ filter: "invert(100%)" }} alt="" />
        <div>Back</div>
      </div>
      <Gallery />
      <View />
    </Suspense>
  )
}
export default Favorites
