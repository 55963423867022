import { Fragment, useState, memo } from "react"
import styled from "styled-components"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"

import Popup from "@components/Popup"
import InfoCard from "./InfoCard"

type FloorplanProps = {
   unit: any
}

const Floorplan: React.FC<FloorplanProps> = memo(function Floorplan({ unit }) {
   const [popupOpen, setPopupOpen] = useState(false)
   const [activeDuplexFloorplan, setActiveDuplexFloorplan] = useState("UPPER")
   const toggleDuplexFloorplan = () => {
      setActiveDuplexFloorplan(
         activeDuplexFloorplan === "UPPER" ? "LOWER" : "UPPER"
      )
   }

   const exceptions = ["PH16A", "PH16D", "PH16E", "PH17A"]
   const isDuplex =
      unit.unitType?.startsWith("TH") ||
      (unit.unitType?.startsWith("PH") && !exceptions.includes(unit.unitType))

   return (
      <Fragment>
         <Wrapper>
            <div className="images">
               <div className="floorplan">
                  <img
                     alt="Floorplan"
                     src={`/assets/images/residences/floorplans/${
                        unit.unitType
                     }-F${isDuplex ? "-" + activeDuplexFloorplan : ""}.png`}
                     onClick={() => setPopupOpen(true)}
                  />
               </div>
               <div className="plate">
                  <img
                     alt="Floorplan"
                     src={`/assets/images/residences/floorplans/${
                        unit.unitType
                     }-P${isDuplex ? "-" + activeDuplexFloorplan : ""}.png`}
                  />
               </div>
            </div>
         </Wrapper>
         <Popup
            openState={popupOpen}
            setOpenState={setPopupOpen}
            durationMS={500}
            closeColor="black"
            style={{
               backgroundColor: "rgba(255, 255, 255, 1)"
            }}
         >
            <TransformWrapper
               minScale={1}
               maxScale={3}
               limitToBounds={true}
               smooth={true}
               centerZoomedOut={true}
               doubleClick={{
                  mode: "reset"
               }}
            >
               <TransformComponent
                  wrapperClass="pinch-container"
                  contentClass="pinch-container"
               >
                  <PopupPlan
                     src={`/assets/images/residences/floorplans/${
                        unit.unitType
                     }-F${isDuplex ? "-" + activeDuplexFloorplan : ""}.png`}
                  />
                  <DuplexToggle
                     className={`duplex-toggle ${isDuplex ? "show" : "hide"}`}
                  >
                     <span
                        onClick={toggleDuplexFloorplan}
                        className={
                           activeDuplexFloorplan === "UPPER" ? "active" : ""
                        }
                     >
                        UPPER
                     </span>
                     <span
                        onClick={toggleDuplexFloorplan}
                        className={
                           activeDuplexFloorplan === "LOWER" ? "active" : ""
                        }
                     >
                        LOWER
                     </span>
                  </DuplexToggle>
               </TransformComponent>
            </TransformWrapper>
            <InfoCard
               selectedUnit={unit}
               style={{
                  position: "absolute",
                  top: "1em",
                  left: "1em",
                  padding: "3em",
                  backgroundColor: "rgba(255,255,255,0.8)",
                  backdropFilter: "blur(10px)"
               }}
            />
         </Popup>
      </Fragment>
   )
})

export default Floorplan

const Wrapper = styled.div`
   position: absolute;
   width: 100%;
   margin-left: auto;
   height: 100%;
   display: flex;
   justify-content: center;
   padding: 2em;

   .images {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;

      .floorplan {
         position: relative;
         height: 100%;
         width: 100%;
         padding: 3em;
         cursor: pointer;

         img {
            position: absolute;
            top: 50%;
            right: 10%;
            transform: translate(0%, -40%);
            object-fit: contain;
            width: clamp(50%, 90%, 600px);
            height: clamp(50%, 90%, 600px);

            @media (max-width: 600px) and (orientation: portrait) {
               width: clamp(40%, 50%, 300px);
               height: clamp(40%, 50%, 300px);
               left: 50%;
               transform: translate(-50%, 0%);
            }
            @media (max-width: 900px) and (orientation: landscape) {
               width: clamp(70%, 50%, 300px);
               height: clamp(70%, 50%, 300px);
               left: 35%;
               transform: translate(0%, -20%);
            }
         }
      }

      .plate {
         height: 15%;
         width: 100%;
         padding: 2em;
         display: flex;
         justify-content: flex-end;
         align-items: center;

         img {
            object-fit: contain;
            width: auto;
            height: 100%;
         }
      }
   }
`

const PopupPlan = styled.img`
   position: absolute;
   top: 50%;
   left: 50%;
   width: 60%;
   height: 60%;
   transform: translate(-50%, -50%);
   transform-origin: center;
   background: rgba(255, 255, 255, 1);
   object-fit: contain;
   object-position: center;
   transition: opacity 0.4s ease-out;
`

const DuplexToggle = styled.div`
   position: absolute;
   z-index: 12;
   font-weight: bold;
   bottom: 4em;
   left: 4em;

   &.show {
      opacity: 1;
      display: flex;
      align-items: center;
      pointer-events: auto;
      transition: all 0.3s ease-in-out 0s;
   }

   &.hide {
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s ease-in-out;
   }

   span {
      color: #000;
      font-size: 2em;
      background: none;
      padding: 0.5em 1em;
      border: 1px solid #000;
      transition: all 0.3s ease-in-out;

      &.active {
         background-color: rgba(0, 0, 0, 0.8);
         color: white;
      }
   }
`
