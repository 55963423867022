import { InitialState } from "./types.store"

export const initialState: InitialState = {
   residenceLiveData: [],
   selectedUnit: null,
   imageExpanded: false,
   galleryVisible: false,
   selectedGalleryImage: null,
   selectedGalleryCategory: "Interiors",
   residenceOfflineData: [],
   unitDetailsSection: null,
   activeDuplexFloorplan: "UPPER",
   activeView: null,
   popupGallery: false,
   popupGalleryIndex: 0,
   popupView: false
}
